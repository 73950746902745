import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Label component is used to add contextual metadata to a design. Visually it styles text, adds padding, and rounded corners.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`  <Label variant="small" outline borderColor="red.2" mr={2} color="red.4">small</Label>
  <Label variant="medium" mr={2}>medium (default)</Label>
  <Label variant="large" mr={2}>large</Label>
  <Label variant="xl">xl label</Label>
  
  <Box mt={4}/>
  <Label variant="medium" bg="#A575FF" m={1}>good first issue</Label>
  <Label variant="medium" bg="#FF75C8" m={1}>🚂 deploy: train</Label>
  <Label variant="medium" bg="#1C90FA" m={1}>css</Label>
  <Label variant="medium" bg=" #FFF06C" color="#24292E" m={1}>documentation</Label>
  <Label variant="medium" bg="#656BFE" m={1}>uchiru</Label>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Label components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`outline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Creates an outline style label`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'medium'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Can be one of `}<inlineCode parentName="td">{`small`}</inlineCode>{`, `}<inlineCode parentName="td">{`medium`}</inlineCode>{` (default), `}<inlineCode parentName="td">{`large`}</inlineCode>{` or `}<inlineCode parentName="td">{`xl`}</inlineCode>{` .`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dropshadow`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Adds a dropshadow to the label`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`bg`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'gray.5'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Part of the `}<inlineCode parentName="td">{`COMMON`}</inlineCode>{` system props, used to change the background of the label.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      